import React, { useEffect } from 'react';
import ArrowRight from '../../../assets/cut-right-arrow.svg';
import DownArrow from '../../../assets/cut-bottom-arrow.svg';
import { useStyles } from './ListsStyles';
import { useSortableData } from '../../../Utils/SortGenericList';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

type LocalStore = [AgenciesStore];

const AgencyAdminsList = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(['settings', 'icon-alt', 'common']);

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {
    currentAgency,
    currentAgencyAgencyAdmins,
    currentAgencyAgencyAdminsStatus,
  } = agenciesStore;

  const { items: sortedList, requestSort, sortConfig } = useSortableData(currentAgencyAgencyAdmins, {
    key: null, direction: 'asc',
  });

  useEffect(() => {
    if (currentAgency?.uid && currentAgencyAgencyAdminsStatus !== 'SUCCESS') {
      void agenciesStore.refreshAgencyAdminsWithSettings();
    }
  }, [currentAgency?.uid]);

  //@todo petit bug sur le tri, si on clique sur trier par profile puis sur identifiant les animations se jouent mais le tri ne s'update pas
  const getSortIcon = (key: string) => {
    return (
      <img
        src={DownArrow}
        alt={t('sortIconAlt', { ns: 'icon-alt' })}
        className={`
          ${classes.sortIcon} ${sortConfig.key === key && sortConfig.direction === 'asc'
          ? classes.sortIconAsc
          : classes.sortIconDesc
        }`}
      />
    );
  };

  return (
    ['LOADING', 'PENDING'].includes(currentAgencyAgencyAdminsStatus) ? <CircularProgress color={'primary'}/> :
      <table className={classes.table}>
        <thead>
        <tr>
          <th className={classes.tableHeader} onClick={() => requestSort('email')}>
            {t('email', { ns: 'common' })} {getSortIcon('email')}
          </th>
          <th className={classes.tableHeader} onClick={() => requestSort('firstName')}>
            {t('name', { ns: 'common' })} {getSortIcon('name')}
          </th>
          <th className={classes.tableHeader}>{t('profile')}</th>
          <th className={classes.tableHeader}>{t('managementUnits')}</th>
          <th className={classes.tableHeader}></th>
        </tr>
        </thead>
        <tbody>
        {sortedList.map((agencyAdmin, index) => {
          return (
            <tr key={index} className={classes.tableRow}>
              <td className={classes.cell}>{agencyAdmin.email}</td>
              <td className={classes.cell}>{agencyAdmin.firstName + ' ' + agencyAdmin.lastName.toUpperCase()}</td>
              <td className={classes.cell}>
                <span className={classes.profileBadge}>{agencyAdmin.profile.profileName}</span>
              </td>
              <td className={classes.cell}>
                <span className={classes.unitBadge}>{agencyAdmin.managementUnits[0].name}</span>
              </td>
              <td className={classes.cell}>
                <img src={ArrowRight} alt="right arrow"/>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
  );
});

export default AgencyAdminsList;