import { DiscreteVoucherModel } from '../../models/discreteVouchers/DiscreteVoucher.model';

export const DEFAULT_VAT_RATE = 20;

export function getIncludingTaxesAmount(amountExcl: number, vatRate: number): number {
  const result = amountExcl + amountExcl * (vatRate / 100);

  return Number(result.toFixed(2));
}

export function getExcludingTaxesAmount(amountIncl: number, vatRate: number): number {
  return Math.round(amountIncl / (1 + vatRate / 100));
}

// maybe this should directly be in voucher's data ?
export function getSubAndPimRealAmounts(voucher: DiscreteVoucherModel) {
  const { agencyParticipationAmount, subAmount = 0, subVatRate = DEFAULT_VAT_RATE } = voucher;
  // calculate real pim and sub amounts. We consume sub first then pim
  const subAmountTTC: number = Math.min(agencyParticipationAmount, getIncludingTaxesAmount(subAmount, subVatRate));
  const pimAmountTTC: number = agencyParticipationAmount - subAmountTTC;  // 0 if agencyParticipationAmount <= subAmount
  const subAmountHT: number = getExcludingTaxesAmount(subAmountTTC, subVatRate);
  const pimAmountHT: number = getExcludingTaxesAmount(pimAmountTTC, subVatRate);

  return {
    subAmountTTC,
    subAmountHT,
    pimAmountTTC,
    pimAmountHT,
  };
}
