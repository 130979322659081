import { COLORS, PageBlock } from '../../Style/Style';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import BackButton from '../../Component/BackButton/BackButtonComponnent';
import InputComponent from '../../Component/InputComponent';
import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { InputSelect } from '../../Style/MuiStyles/Select/Select';
import Downarrow from '../../assets/down-arrow';
import { useStyles } from './AddAgencyAdminPageStyle';
import Button from '../../Style/MuiStyles/Button';

const AddAgencyAdminPage = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(['settings', 'common']);

  const listOfProfile: any[] = [
    { label: 'Admin', value: 'admin' },
    { label: 'Super Admin', value: 'superAdmin' },
  ];
  const listOfGestionUnit = [
    { label: 'Besançon DDT', value: 'besancon-ddt' },
    { label: 'Besançon Commissariat', value: 'besancon-commissariat' },
    { label: 'Paris DDT', value: 'paris-ddt' },
    { label: 'Paris Gendarmerie', value: 'paris-gendarmerie' },
  ].sort((a, b) => a.label.localeCompare(b.label));
  listOfGestionUnit.unshift({ label: 'Toutes', value: 'all' });

  return (
    <PageBlock>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton
            linkTo={'/settings/agencyadmins'}
          />
          <h1>{t('addEmpUserSubTitle')}</h1>
        </div>
      </div>

      <h2 className={classes.subTitle}>IDENTITÉ</h2>
      <div className={classes.identityWrapper}>
        <InputComponent
          title={'Email'}
          isRequired={true}
          onChange={void 0}/>

        <InputComponent
          title={'Nom'}
          isRequired={true}
          onChange={void 0}/>

        <InputComponent
          title={'Prénom'}
          isRequired={true}
          onChange={void 0}/>
      </div>

      <h2 className={classes.subTitle}>PROFIL</h2>
      <div>
        <Select
          value="admin"
          input={<InputSelect title="Profil" required/>}
          IconComponent={(props) => <div
            style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
          </div>}
          label="Profil"
        >
          {
            listOfProfile.map((title, index) =>
              <MenuItem key={index} value={title.value}>{t(title.label)}</MenuItem>,
            )
          }
        </Select>
      </div>

      <h2 className={classes.subTitle}>UNITÉS DE GESTION</h2>
      <Select
        input={<InputSelect title={t('gestionUnit')} required/>}
        IconComponent={(props) => <div
          style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
        </div>}
      >
        {
          listOfGestionUnit.map((item, index) =>
            <MenuItem key={index} value={item.value}>{t(item.label)}</MenuItem>,
          )
        }
      </Select>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          type="button">
          {t('cancel', { ns: 'common' })}
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="button">
          {t('add', { ns: 'common' })}
        </Button>
      </div>
    </PageBlock>
  );
});

export default AddAgencyAdminPage;