import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  lineWrapper: {
    marginTop: '50px',
  },
  identityWrapper: {
    display: 'flex',
    gap: '15px',
  },
  subTitle: {
    fontWeight: 400,
    letterSpacing: '2px',
    fontSize: '16px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    gap: '15px',
  }
});