import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import {
  getTestFieldResult,
  isAcceptedTitle,
  isAlphaNumeric,
  isAlphaNumericWithSpace,
  isDate,
  isEmail,
  isNumber,
  isRequired,
  isRespectedMaxLengthCurried,
  isString,
  isStringWithSpace,
  isValidIBAN,
  isValueBetweenCurried,
  isYesNoQuestionValue,
  isValueGreater,
  RowTestResult,
  FileTestSet,
  FieldTesterFunction,
  isFileUTF8,
  hasFileDuplicateValues,
  isFileNotEmpty, ObjectTestSet, transformCountryCode, transformBoolean, transformNumber,
} from './BaseTester.service';
import moment from 'moment';
import {
  getTimeStampMinusMonth, getTimeStampPlusMonth,
  getTimeStampStartOfDay,
} from '../../assets/utils/dates/getParsedDate.util';
import { BeneficiaryClassificationModel } from '../../assets/models/beneficiaries/BeneficiaryClassification.model';
import { CreateBeneficiaryRequest } from '../../assets/requests/beneficiaries/CreateBeneficiary.request';
import { AgencyModel } from '../../assets/models/agencies/Agency.model';
import { getContractSpecification } from '../../assets/utils/agencies/contractTypes.util';
import { UploadBeneficiaryRequest } from '@assets/requests/beneficiaries/UploadBeneficiary.request';
import { ManagementUnitModel } from '@assets/models/agencies/ManagementUnit.model';
import {
  OrganizationInformationsModel,
  OrganizationInformationType,
} from '@assets/models/organizationInformations/OrganizationInformations.model';

export const postcodeLength: number = 5;
export const ibanLength: number = 27;
export const workingDayNumberScope = { min: 0, max: 20 };
export const dailyVoucherNumberScope = { min: 0, max: 3 };
export const monthlyVoucherNumberScope = { min: 0, max: 23 };
export const recharginDaysNumberScope = { min: 0, max: 999 };
export const yearScope = { min: 2022, max: 2122 };
export const monthScope = { min: 1, max: 12 };
export const firstRightDateScope = { lowerRange: 2, upperRange: 6 };

export const uploadBeneficiaryKeysMap = {
  'Matricule entreprise': 'registrationNumber',
  'Email': 'email',
  'Civilite (M./Mme)': 'title',
  'Nom': 'lastName',
  'Prenom': 'firstName',
  'Pays de residence': 'countryCode',
  'Adresse': 'streetAddress',
  'Complement d\'adresse': 'additionalAddress',
  'code postal': 'postalCode',
  'Ville': 'city',
  'IBAN': 'iban',
  'Travaille le dimanche (O/N)': 'activeSundaysAndHolidays',
  'NB jours par defaut': 'numberOfWorkingDays',
  'date debut droit (dd/mm/yyyy)': 'firstRightDate',
  'Classification': 'classificationCode',

  registrationNumber: 'Matricule entreprise',
  email: 'Email',
  title: 'Civilite (M./Mme)',
  lastName: 'Nom',
  firstName: 'Prenom',
  countryCode: 'Pays de residence',
  streetAddress: 'Adresse',
  additionalAddress: 'Complement d\'adresse',
  postalCode: 'code postal',
  city: 'Ville',
  iban: 'IBAN',
  activeSundaysAndHolidays: 'Travaille le dimanche (O/N)',
  numberOfWorkingDays: 'NB jours par defaut',
  firstRightDate: 'date debut droit (dd/mm/yyyy)',
  classificationCode: 'Classification',
};

export const beneficiaryRechargingKeysMap = {
  'Annee': 'year',
  'Mois': 'month',
  'Matricule': 'registrationNumber',
  'Nb jours': 'numberOfWorkingDays',
  year: 'Annee',
  month: 'Mois',
  registrationNumber: 'Matricule',
  numberOfWorkingDays: 'Nb jours',
};

export interface CodedListItem {
  uid: string;
  code: number;
}

export interface CsvBeneficiary {
  registrationNumber: string;
  classificationCode?: string;
  managementUnitCode?: string;
  email: string;
  title: string;
  lastName: string;
  firstName: string;
  countryCode?: string;
  streetAddress?: string;
  additionalAddress?: string;
  postalCode?: string;
  city?: string;
  iban?: string;
  activeSundaysAndHolidays: string;
  numberOfWorkingDays?: string;
  firstRightDate: string;
  activeSaturdays?: string;
  dailyVoucherUsageLimit?: string;
  monthlyVoucherUsageLimit?: string;

  administrationCode?: string;
  ministryCode?: string;
  bopCode?: string;
}

export interface BeneficiaryRowTestResult extends RowTestResult<CsvBeneficiary> {
  firstName: string;
  lastName: string;
  userValidity: BeneficiaryExistsStatus;
  uploadModel: UploadBeneficiaryRequest
}

export type BeneficiaryExistsStatus = 'USER_UNKNOWN' | 'USER_EXISTS' | 'REGISTRATION_NUMBER_OR_EMAIL_EXISTS' | 'EMPTY_LINE';

export interface CsvBeneficiaryRecharging {
  year: string;
  month: string;
  registrationNumber: string;
  numberOfWorkingDays: string;
}

// region file testers

// endregion

// region fields testers
function isRegistrationNumberExistsCurried(existingBeneficiaryList: BeneficiaryModel[]): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.registrationNumber === value) === undefined,
    'REGISTRATION_NUMBER_ALREADY_TAKEN',
    { expectedValue: value },
  );
}

function isBeneficiaryEmailExistsCurried(existingBeneficiaryList: BeneficiaryModel[]): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.email === value) === undefined,
    'EMAIL_ALREADY_TAKEN',
  );
}

function isRegistrationNumberFoundCurried(existingBeneficiaryList: BeneficiaryModel[]): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.registrationNumber === value) !== undefined,
    'USER_REF_NOT_FOUND',
    { expectedValue: value },
  );
}

function isDateWithinAcceptedRange(lowerRange: number, upperRange: number): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => {
      const date: number = moment(value, 'DD/MM/YYYY', true).toDate().getTime();
      const normalizedDate: number = getTimeStampStartOfDay(date);
      const minimumAcceptedDate: number = getTimeStampStartOfDay(getTimeStampMinusMonth(lowerRange));
      const maximumAcceptedDate: number = getTimeStampStartOfDay(getTimeStampPlusMonth(upperRange));

      return minimumAcceptedDate < normalizedDate && maximumAcceptedDate > normalizedDate;
    },
    'NOT_ACCEPTABLE_RANGE_DATE',
    { lowerRange, upperRange },
  );
}

function isValidBeneficiaryClassificationFormat(useBeneficiaryClassification: boolean): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => {
      if (value.trim() === '') {
        return !useBeneficiaryClassification;
      }

      return true;
    },
    'INVALID_BENEFICIARY_CLASSIFICATION_FORMAT'
  )
}

function isExistingBeneficiaryClassification(useBeneficiaryClassification: boolean, classifications: BeneficiaryClassificationModel[]): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => {
      if (!useBeneficiaryClassification) {
        return value === '1' || value.trim() === '';
      }

      const existing: BeneficiaryClassificationModel = classifications.find(classification => classification.code === Number(value));

      return !!existing;
    },
    `UNKNOWN_BENEFICIARY_CLASSIFICATION_${ useBeneficiaryClassification ? 'MULTICLASS' : 'MONOCLASS'}`,
    { expectedValue: value }
  )
}

function codeExistsInList(list: { code: number }[], errorMessage: string): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => {
      const existing = list.find(item => item.code === Number(value));

      return !!existing;
    },
    errorMessage,
    { expectedValue: value }
  )
}

function codeExistsInOrganizationList(list: OrganizationInformationsModel[], type: OrganizationInformationType): FieldTesterFunction {
  return (value: string) => getTestFieldResult(
    value,
    () => {
      const existing = list.find(item => item.code === Number(value) && item.type === type);

      return !!existing;
    },
    'UNKNOWN_ORGANIZATION',
    { expectedValue: value }
  )
}
// endregion

function transformCodeFromList<ItemType extends CodedListItem = CodedListItem>(list: ItemType[]) {
  return (code: string): string => {
    let unitCode = Number(code);

    const item: ItemType = list.find(item => item.code === unitCode);

    return item?.uid;
  }
}

function transformCodeFromOrganizationList(list: OrganizationInformationsModel[], type: OrganizationInformationType) {
  return (code: string): string => {
    let unitCode = Number(code);

    const item: OrganizationInformationsModel = list.find(item => item.code === unitCode && item.type === type);

    return item?.uid;
  }
}

export function getUploadBeneficiaryTestSet(agency: AgencyModel | undefined, existingBeneficiaryList: BeneficiaryModel[], classifications: BeneficiaryClassificationModel[], managementUnits: ManagementUnitModel[], organizationInformation: OrganizationInformationsModel[]): FileTestSet<CsvBeneficiary> {
  const { useBeneficiaryClassification = false, useAgencyManagementUnit = false } = agency;
  const { paymentTargets, creditRedemptionMethod } = getContractSpecification(agency);


  // Columns declaration order is important as it determines the order used when generating empty sample files
  const testSet: FileTestSet<CsvBeneficiary> = {
    file: [isFileUTF8, isFileNotEmpty, hasFileDuplicateValues('registrationNumber'), hasFileDuplicateValues('email')],
    columns: {
      registrationNumber: {
        name: 'Matricule entreprise',
        validators: [isRequired, isAlphaNumericWithSpace, isRegistrationNumberExistsCurried(existingBeneficiaryList)],
      },
      email: {
        name: 'Email',
        validators: [isRequired, isEmail, isBeneficiaryEmailExistsCurried(existingBeneficiaryList)],
      },
      title: {
        name: 'Civilite (M./Mme)',
        validators: [isRequired, isAcceptedTitle],
        transform: (value: string) => {
          switch (value) {
            case 'M.':
              return 'Mr';
            case 'Mme':
              return 'Mrs';
            default:
              return 'Unknown';
          }
        },
      },
      lastName: {
        name: 'Nom',
        validators: [isRequired, isStringWithSpace],
      },
      firstName: {
        name: 'Prenom',
        validators: [isRequired, isStringWithSpace],
      },
    }
  }

  // Address & BankInfo
  if (paymentTargets.includes('BENEFICIARY')) {
    testSet.columns = {
      ...testSet.columns,
      countryCode: {
        name: 'Pays de residence',
        validators: [isString],
        transform: transformCountryCode,
      },
      streetAddress: {
        name: 'Adresse',
        validators: [isAlphaNumericWithSpace],
      },
      additionalAddress: {
        name: 'Complement d\'adresse',
        validators: [isAlphaNumericWithSpace],
      },
      postalCode: {
        name: 'code postal',
        validators: [isRequired, isNumber, isRespectedMaxLengthCurried(postcodeLength)],
      },
      city: {
        name: 'Ville',
        // TODO implement country code validator
        validators: [isRequired, isAlphaNumericWithSpace],
      },
      iban: {
        name: 'IBAN',
        validators: [isRequired, isAlphaNumeric, isValidIBAN],
      },
    }
  }

  if (creditRedemptionMethod === 'LINEAR') {
    testSet.columns = {
      ...testSet.columns,
      numberOfWorkingDays: {
        name: 'NB jours par defaut',
        validators: [isRequired, isNumber, isValueBetweenCurried(workingDayNumberScope.min, workingDayNumberScope.max)],
        transform: transformNumber,
      },
    }
  } else {
    testSet.columns = {
      ...testSet.columns,
      dailyVoucherUsageLimit: {
        name: 'NB repas par jour',
        validators: [isRequired, isNumber, isValueBetweenCurried(dailyVoucherNumberScope.min, dailyVoucherNumberScope.max)],
        transform: transformNumber,
      },
      monthlyVoucherUsageLimit: {
        name: 'NB repas par mois',
        validators: [isRequired, isNumber, isValueBetweenCurried(monthlyVoucherNumberScope.min, monthlyVoucherNumberScope.max)],
        transform: transformNumber,
      },
    }
  }

  // Activity
  testSet.columns = {
    ...testSet.columns,
    firstRightDate: {
      name: 'Date debut droit (dd/mm/yyyy)',
      validators: [isRequired, isDate, isDateWithinAcceptedRange(firstRightDateScope.lowerRange, firstRightDateScope.upperRange)],
      transform: (value: string) => getTimeStampStartOfDay(moment(value, 'DD/MM/YYYY', true).toDate().getTime()),
    },
  }

  if (agency.contractType === 'PUBLIC_MEAL_SUBSIDY') {
    testSet.columns = {
      ...testSet.columns,
      activeSaturdays: {
        name: 'Travaille le samedi (O/N)',
        validators: [isRequired, isYesNoQuestionValue],
        transform: transformBoolean,
      },
      activeSundaysAndHolidays: {
        name: 'Travaille le dimanche et JF (O/N)',
        validators: [isRequired, isYesNoQuestionValue],
        transform: transformBoolean,
      },
    }
  } else {
    testSet.columns = {
      ...testSet.columns,
      activeSundaysAndHolidays: {
        name: 'Travaille le dimanche (O/N)',
        validators: [isRequired, isYesNoQuestionValue],
        transform: transformBoolean,
      },
    }
  }

  testSet.columns = {
    ...testSet.columns,
    classificationCode: {
      name: 'Classification',
      validators: [isNumber, isValidBeneficiaryClassificationFormat(useBeneficiaryClassification), isExistingBeneficiaryClassification(useBeneficiaryClassification, classifications)],
      optional: !useBeneficiaryClassification,
      transform: (value: string) => {
        let classificationCode = value;

        if (!useBeneficiaryClassification && classificationCode === '') {
          classificationCode = '1';
        }

        return transformCodeFromList(classifications)(classificationCode);
      }
    },
  }

  if (useAgencyManagementUnit) {
    testSet.columns = {
      ...testSet.columns,
      managementUnitCode: {
        name: 'Unite gestion',
        validators: [isRequired, isNumber, codeExistsInList(managementUnits, 'UNKNOWN_MANAGEMENT_UNIT')],
        transform: transformCodeFromList(managementUnits),
        transformToPropertyName: 'agencyManagementUnitId',
      },
    }
  }

  if (agency.contractType === 'PUBLIC_MEAL_SUBSIDY') {
    testSet.columns = {
      ...testSet.columns,
      administrationCode: {
        name: 'Administration',
        validators: [isRequired, isNumber, codeExistsInOrganizationList(organizationInformation, 'administration')],
        transform: transformCodeFromOrganizationList(organizationInformation, 'administration'),
        transformToPropertyName: 'organizationAdministrationId',
      },
      ministryCode: {
        name: 'Ministere',
        validators: [isRequired, isNumber, codeExistsInOrganizationList(organizationInformation, 'ministry')],
        transform: transformCodeFromOrganizationList(organizationInformation, 'ministry'),
        transformToPropertyName: 'organizationMinistryId',
      },
      bopCode: {
        name: 'BOP',
        validators: [isRequired, isNumber, codeExistsInOrganizationList(organizationInformation, 'bop')],
        transform: transformCodeFromOrganizationList(organizationInformation, 'bop'),
        transformToPropertyName: 'organizationBopId',
      },
    }
  }

  return testSet;
}

export function getFormBeneficiaryTestSet(existingBeneficiaryList: BeneficiaryModel[]): ObjectTestSet<CreateBeneficiaryRequest> {
  return {
    registrationNumber: {
      name: 'registrationNumber',
      validators: [isRequired, isAlphaNumeric, isRegistrationNumberExistsCurried(existingBeneficiaryList)],
    },
    email: {
      name: 'email',
      validators: [isRequired, isEmail, isBeneficiaryEmailExistsCurried(existingBeneficiaryList)],
    },
    iban: {
      name: 'iban',
      validators: [isRequired, isAlphaNumeric, isRespectedMaxLengthCurried(ibanLength)],
    },
  };
}

export function getBeneficiaryRechargingTestSet(existingBeneficiaryList: BeneficiaryModel[]): FileTestSet<CsvBeneficiaryRecharging> {
  return {
    file: [isFileUTF8, isFileNotEmpty, hasFileDuplicateValues('registrationNumber')],
    columns: {
      year: {
        name: 'Annee',
        validators: [isRequired, isNumber, isValueGreater(yearScope.min)],
      },
      month: {
        name: 'Mois',
        validators: [isRequired, isNumber, isValueBetweenCurried(monthScope.min, monthScope.max)],
      },
      registrationNumber: {
        name: 'Matricule',
        validators: [isRequired, isAlphaNumeric, isRegistrationNumberFoundCurried(existingBeneficiaryList)],
      },
      numberOfWorkingDays: {
        name: 'Nb jours',
        validators: [isRequired, isNumber, isValueBetweenCurried(recharginDaysNumberScope.min, recharginDaysNumberScope.max)],
      },
    }
  };
}
