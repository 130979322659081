import {
  AgencyAdminManagementUnitSettingModel,
  AgencyAdminModel,
} from '../assets/models/agencyAdmin/AgencyAdmin.model';
import { callable } from './httpWrapper.service';

export async function getConnectedAgencyAdmin(): Promise<AgencyAdminModel> {
  return await callable(
    'getConnectedAgencyAdmin',
  );
}

export async function getAllManagementUnitsSettingsOfAgencyAdmin(agencyId: string, agencyAdminId: string): Promise<AgencyAdminManagementUnitSettingModel[]> {
  return callable(
    'getAllManagementUnitsSettingsOfAgencyAdmin',
    { agencyId, agencyAdminId },
  );
}
