import moment from 'moment';
import { isIBAN } from '../../assets/utils/checkTypes/isIBAN';
import * as regexList from '../regexList';
import { SelectCountryCodeModel } from '@assets/models/specials/selectListData.model';
import { ListOfCountryCode } from '../../assets/models/specials/listOfCountryCode';

// region Interfaces & Types
export type KeyMap<T extends Record<string, any>> = {
  [name in string]: string | keyof T;
} & {
  [name in keyof T]: string
};

  export interface FieldTestResult {
  passed: boolean;
  errorMessage?: string;
}

export interface FieldErrorDetails {
  expectedValue?: string;
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  lowerRange?: number;
  upperRange?: number;
}

export interface FieldTestReport<T> extends FieldTestResult, FieldErrorDetails {
  fieldName: keyof T;
  columnName: string;
}

export interface ObjectTestResult<T> {
  passed: boolean;
  errors?: FieldTestReport<T>[];
}

export interface RowTestResult<T> extends ObjectTestResult<T> {
  rowIndex: number;
  csvEntity: CsvRowEntity<T>;
}

export interface FileSingleTestResult {
  passed: boolean;
  errorMessage?: string;
  errorDetails?: FileErrorDetails;
}

export type FileErrorDetails = Record<string, any>;

export interface FileTestResult<T> {
  passed: boolean;
  errors?: FileSingleTestResult[];
  rows: RowTestResult<T>[];
}

export type FieldTesterFunction = (value: string) => FieldTestResult;

export type FieldDefinition<T> = {
  name: string;
  validators: FieldTesterFunction[];
  optional?: boolean;
  transform?: (value: string) => any;
  transformToPropertyName?: string;
};

export type ObjectTestSet<T> = {
  [key in keyof T]?: FieldDefinition<T>;
}

export type FileTesterFunction<T = any> = (headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap) => FileSingleTestResult;

export interface FileTestSet<T> {
  file: FileTesterFunction<T>[];
  columns: ObjectTestSet<T>;
}

export type CsvRowEntity<T> = { [key in keyof T]: string };
// endregion

// region Constants
const acceptedTitle: string[] = ['M.', 'Mme'];
const acceptedYesNoQuestion: string[] = ['O', 'N'];

// endregion

export function getTestFileResult<T = any>(headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap, testCondition: () => boolean, errorMessage: string, errorDetails?: FileErrorDetails): FileSingleTestResult {
  if (testCondition()) {
    return {
      passed: true,
    };
  }

  return {
    passed: false,
    errorMessage,
    errorDetails,
  };
}

export function getTestFieldResult(value: string, testCondition: () => boolean, errorMessage: string, errorDetails?: FieldErrorDetails): FieldTestResult {
  if (testCondition()) {
    return {
      passed: true,
    };
  }

  return {
    passed: false,
    errorMessage: errorMessage,
    ...(errorDetails || {}),
  };
}

// region File Validators
export function isFileUTF8<T = any>(headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap): FileSingleTestResult {
  return getTestFileResult(
    headerRow, dataRows, csvEntities, keysMap,
    () => {
      const fileContent = [headerRow, ...dataRows];

      return fileContent.every(row => row.every(cell => !/\uFFFD/.test(cell)));
    },
    'NOT_UTF8',
  )
}

export function isFileNotEmpty<T = any>(headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap): FileSingleTestResult {
  return getTestFileResult(
    headerRow, dataRows, csvEntities, keysMap,
    () => dataRows.length > 0,
    'FILE_EMPTY',
  )
}

export function hasFileExpectedColumns<T = any>(expectedCount: number): FileTesterFunction<T> {
  return (headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap): FileSingleTestResult => {
    return getTestFileResult(
      headerRow, dataRows, csvEntities, keysMap,
      () => {
        const hasCorrectNumberOfColumns = (headerRow.length === expectedCount)
          && dataRows.every((row: string[]) => row.length === expectedCount);
        const hasEmptyHeader = headerRow.some((header: string) => !header);

        return hasCorrectNumberOfColumns && !hasEmptyHeader;
      },
      'INVALID_COLUMNS',
      { expectedCount }
    )
  }
}

export function hasFileDuplicateValues<T = any>(fieldName: keyof T): FileTesterFunction<T> {
  return (headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap): FileSingleTestResult => {
    const counters = csvEntities
      .reduce((counters: { [value: string]: number }, csvEntity: CsvRowEntity<T>) => {
        const value = csvEntity[fieldName];

        if (!counters[value]) {
          counters[value] = 0;
        }
        counters[value] += 1;

        return counters;
      }, {});

    const duplicates: string[] = Object.keys(counters)
      .filter((fieldValue: string) => counters[fieldValue] > 1);
    const hasDuplicates: boolean = duplicates.length > 0;

    if (hasDuplicates) {
      return {
        passed: false,
        errorMessage: 'DUPLICATE_VALUES',
        errorDetails: { fieldName, duplicates }
      }
    }

    return {
      passed: true,
    }
  }
}
// endregion


// region Field Validators
export function isString(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => (value
      ? regexList.regexOnlyLetters.test(String(value))
      : true),
    'NOT_A_STRING',
  );
}

export function isStringWithSpace(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => regexList.regexOnlyLettersWithSpace.test(String(value)),
    'NOT_A_STRING',
  );
}

export function isNumber(value: string): FieldTestResult {
  const stringToNumber = Number(value);

  return getTestFieldResult(
    value,
    () => !isNaN(stringToNumber),
    'NOT_A_NUMBER',
  );
}

export function isRequired(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => value !== '' && value.trim() !== '',
    'IS_REQUIRED',
  );
}

export function isAlphaNumeric(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => regexList.regexAlphaNumeric.test(String(value)),
    'NOT_AN_ALPHANUMERIC',
  );
}

export function isAlphaNumericWithSpace(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => value.length === 0 || regexList.regexAlphaNumericWithSpace.test(String(value)),
    'NOT_AN_ALPHANUMERIC',
  );
}

export function isEmail(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => regexList.regexEmail.test(String(value)),
    'NOT_AN_EMAIL',
  );
}

export function isDate(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => moment(value, 'DD/MM/YYYY', true).isValid(),
    'NOT_A_DATE',
  );
}
export function isValueGreater(minValue: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => Number(value) >= minValue,
    'NOT_VALUE_GREATER',
    { minValue},
  );
}

export function isValidIBAN(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => isIBAN(value),
    'NOT_A_VALID_IBAN',
  );
}

export function isRespectedLengthCurried(minLength: number, maxLength: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => (value.length >= minLength && value.length <= maxLength),
    'NOT_RESPECT_LENGTH',
    { minLength, maxLength },
  );
}

export function isRespectedMaxLengthCurried(maxLength: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => value.length <= maxLength,
    'NOT_RESPECT_MAX_LENGTH',
    { maxLength },
  );
}

export function isValueBetweenCurried(minValue: number, maxValue: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => Number(value) >= minValue && Number(value) <= maxValue,
    'NOT_VALUE_BETWEEN',
    { minValue, maxValue },
  );
}

export function isAcceptedValue(value: string, acceptedValues: string[], errorMessage: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => acceptedValues.includes(value),
    errorMessage,
  );
}

export function isYesNoQuestionValue(value: string): FieldTestResult {
  return isAcceptedValue(
    value,
    acceptedYesNoQuestion,
    'NOT_YES_NO_VALUE',
  );
}

export function isAcceptedTitle(value: string): FieldTestResult {
  return isAcceptedValue(
    value,
    acceptedTitle,
    'NOT_ACCEPTED_TITLE',
  );
}

// endregion

// region transformers
export function transformNumber(value: string): number {
  return Number(value);
}

export function transformBoolean(value: string): boolean {
  return value === 'O' ? true : false;
}

export function transformCountryCode(value: string): string | undefined {
  const country: SelectCountryCodeModel | undefined = ListOfCountryCode
    .find((country: SelectCountryCodeModel) => country.label === value);
  return country?.value;
}
// endregion

function testEntity<T>(entityToTest: CsvRowEntity<T>, testSet: ObjectTestSet<T>): FieldTestReport<T>[] {
  const entityKeys: (keyof T)[] = Object.keys(entityToTest) as (keyof T)[];

  return entityKeys.reduce((entityErrors: FieldTestReport<T>[], key: keyof T) => {
    const testsToUse: FieldTesterFunction[] = testSet[key]?.validators;

    if (testsToUse) {
      let fieldTestResult: FieldTestResult;

      for (const test of testsToUse) {
        fieldTestResult = test(entityToTest[key].trim());

        if (!fieldTestResult.passed) {
          entityErrors.push({
            ...fieldTestResult,
            fieldName: key,
            columnName: testSet[key].name,
          });

          break;
        }
      }
    }
    return entityErrors;
  }, []);
}

function convertCsvToJson<T>(propertyNamesList: (keyof T)[], csvRawData: string[][]): CsvRowEntity<T>[] {
  return csvRawData.map((row: string[]) => {
    return propertyNamesList.reduce((entity: CsvRowEntity<T>, propertyName: keyof T, index: number) => {
      const value = row[index];
      entity[propertyName] = value?.trim() ?? '';

      return entity;
    }, {} as CsvRowEntity<T>);
  });
}

function getRowsTestResult<T>(csvRawEntities: CsvRowEntity<T>[], testSet: ObjectTestSet<T>): RowTestResult<T>[] {

  return csvRawEntities.map((entity: CsvRowEntity<T>, index: number) => {
    const isEmptyLine: boolean = Object.values(entity).every((item:string) => item.trim() === '');

    if (isEmptyLine) {
      return {
        rowIndex: index,
        passed: true,
        errors: [],
        csvEntity: entity,
      };
    }

    const fieldsTestErrors: FieldTestReport<T>[] = testEntity(entity, testSet);
    const isSuccess: boolean = !fieldsTestErrors.length;

    return {
      rowIndex: index,
      passed: isSuccess,
      errors: fieldsTestErrors,
      csvEntity: entity,
    };
  });
}

export function getObjectTestResult<T>(object, testSet: ObjectTestSet<T>): ObjectTestResult<T> {
  const errors: FieldTestReport<T>[] = testEntity(object, testSet);
  const isSuccess = !errors.length;

  return {
    passed: isSuccess,
    errors: errors,
  };
}

function convertCsvHeaderRowToPropertyNamesList<T>(headerRow: string[], keysMap): (keyof T | undefined)[] {
  return headerRow.map((headerKey: string) => keysMap[headerKey]);
}

function hasEmptyOrUnknownColumns<T>(headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap): FileSingleTestResult {
  const emptyHeaderColumnNumbers: number[] = headerRow
    .map((columnName: string, index: number) => {
      return { columnName, index };
    })
    .filter(({ columnName }) => columnName === '')
    .map(({ index }) => index + 1);
  const hasEmptyHeader: boolean = emptyHeaderColumnNumbers.length > 0;

  if (hasEmptyHeader) {
    return {
      passed: false,
      errorMessage: 'EMPTY_COLUMN_NAME',
      errorDetails: { indices: emptyHeaderColumnNumbers }
    }
  }

  const unknownHeaders = headerRow.filter((columnName) => !(columnName in keysMap));

  if (unknownHeaders.length > 0) {
    return {
      passed: false,
      errorMessage: 'UNKNOWN_COLUMN',
      errorDetails: { unknownColumns: unknownHeaders }
    }
  }

  return {
    passed: true,
  }
}

function hasMissingColumns<T>(testSet: FileTestSet<T>, propertyNamesList: (keyof T)[]): FileTesterFunction<T> {
  return (headerRow: string[], dataRows: string[][], csvEntities: CsvRowEntity<T>[], keysMap): FileSingleTestResult => {
    const testSetKeys: (keyof T)[] = (Object.keys(testSet.columns) as (keyof T)[]).sort();
    const missingTestSetKeys: (keyof T)[] = testSetKeys.filter((key: keyof T) => !propertyNamesList.includes(key) && testSet.columns[key].optional !== true)

    if (missingTestSetKeys.length > 0) {
      return {
        passed: false,
        errorMessage: 'MISSING_TESTSET_KEYS',
        errorDetails: {
          missing: missingTestSetKeys.map(key => ({ fieldName: key, columnName: keysMap[key] }))
        }
      };
    }

    return {
      passed: true,
    }
  }
}

export function analyseUploadingFile<T>(
  headerRow: string[],
  dataRows: string[][],
  testSet: FileTestSet<T>,
): FileTestResult<T> {
  const keysMap = getKeyMap<T>(testSet);

  const propertyNamesList: (keyof T | undefined)[] = convertCsvHeaderRowToPropertyNamesList<T>(headerRow, keysMap);
  const csvRawEntities: CsvRowEntity<T>[] = convertCsvToJson<T>(propertyNamesList, dataRows);

  const fileValidators: FileTesterFunction<T>[] = [
    hasEmptyOrUnknownColumns<T>,
    hasMissingColumns<T>(testSet, propertyNamesList),
    ...testSet.file,
  ];

  const fileTestResult: FileSingleTestResult[] = fileValidators.map((testerFn) => testerFn(headerRow, dataRows, csvRawEntities, keysMap));

  const failedTestsResults = fileTestResult.filter(result => !result.passed);
  if (failedTestsResults.length > 0) {

    return  {
      passed: false,
      errors: [
        ...failedTestsResults,
      ],
      rows: [],
    };
  }

  const rowsTestResult: RowTestResult<T>[] = getRowsTestResult(csvRawEntities, testSet.columns);
  const failedRowTestResults = rowsTestResult.filter(result => !result.passed);

  return {
    passed: failedRowTestResults.length === 0,
    rows: rowsTestResult,
  };
}

function getKeyMap<T>(testSet: FileTestSet<T>): KeyMap<T> {
  return Object.entries(testSet.columns)
    .reduce((acc, [fieldName, fieldDefinition]: [string, FieldDefinition<T>]) => {
      const { name: columnName } = fieldDefinition;

      acc[fieldName] = columnName;
      acc[columnName] = fieldName;

      return acc;
    }, {}) as KeyMap<T>;
}

export function hasFileError<T>(testResult: FileTestResult<T>, errorName: string): boolean {
  return !!getFileError(testResult, errorName);
}

export function getFileError<T>(testResult: FileTestResult<T>, errorName: string): FileSingleTestResult | null {
  return testResult.errors.find(error => error.errorMessage === errorName);
}

export function transformCsvEntityToModel<T>(entity: CsvRowEntity<T>, testSet: ObjectTestSet<T>): T {
  return Object.entries(testSet).reduce((acc, [fieldName, fieldDefinition]: [string, FieldDefinition<T>]) => {
    const initialValue: string = entity[fieldName];
    const value: any = fieldDefinition.transform ? fieldDefinition.transform(initialValue) : initialValue;
    const key: string = fieldDefinition.transformToPropertyName ? fieldDefinition.transformToPropertyName : fieldName;

    acc[key] = value;

    return acc;
  }, {}) as T;
}