import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { useStyles } from './SettingsPageStyles';
import { PageBlock } from '../Style/Style';
import { useTranslation } from 'react-i18next';
import SummaryCard from './Components/SummaryCard/SummaryCard';
import { AgenciesStore } from '../Stores/Agencies.store';
import { getListOfProfiles } from '../assets/utils/security/agencyAdmins.util';

type LocalStore = [AgenciesStore];

const SettingsPage: FunctionComponent = observer(() => {
  const { t } = useTranslation('settings');
  const classes = useStyles();

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const [totalProfiles, setTotalProfiles] = useState<number>(0);

  const { currentAgency, currentAgencyAgencyAdminsStatus } = agenciesStore;
  const fetchTotalProfiles: () => Promise<void> = useCallback(async () => {
    if (currentAgency?.uid) {
      const profiles = await getListOfProfiles(currentAgency.uid);
      setTotalProfiles(profiles.length);
    }
  }, [currentAgency?.uid]);

  useEffect(() => {
    fetchTotalProfiles();
  }, [fetchTotalProfiles]);

  useEffect(() => {
    if (currentAgency?.uid && currentAgencyAgencyAdminsStatus !== 'SUCCESS') {
      void agenciesStore.refreshAgencyAdminsWithSettings();
    }
  }, [currentAgency?.uid]);

  return (
    <PageBlock>
      <h1>{t('title')}</h1>
      <div className={classes.containerCard}>
        <SummaryCard number={totalProfiles} label={t('summaryCardLabel')} linkTo={'profiles'}/>
        <SummaryCard number={agenciesStore.currentAgencyAgencyAdmins.length} label={t('agencyAdmins')} linkTo={'agencyadmins'}/>
      </div>
    </PageBlock>
  );
});

export default SettingsPage;