import { action, makeAutoObservable } from 'mobx';
import { AgencyMillesimeStatusModel } from '@assets/models/millesime/AgencyMillesimeStatus.model';
import { getAgencyCurrentMillesimeStatus, setSelectedRightsReconductionType, resetSelectedRightsReconductionType } from '../Services/millesimeAPI.service';
import { RightsReconductionType } from '@assets/models/agencies/Agency.model';
import { ProductType } from '@assets/models/products/Products.model';

export class MillesimesStore {
  selectedAgencyId: string | null = null;
  isLoadingCurrentMillesimeStatus: boolean = false;
  isSavingRightsReconductionType: boolean = false;
  currentMillesimeStatus: AgencyMillesimeStatusModel[] | null = null;

  get foodVoucherCurrentMillesimeStatus(): AgencyMillesimeStatusModel | null {
    if (!this.currentMillesimeStatus) {
      return null;
    }

    return this.currentMillesimeStatus.find(millesimeStatus => millesimeStatus.productType === 'FOOD_VOUCHER');
  }

  get shouldDisplayFoodVoucherCurrentMillesimeBanner(): boolean {
    const foodVoucherCurrentMillesimeStatus: AgencyMillesimeStatusModel | null = this.foodVoucherCurrentMillesimeStatus;

    if (!foodVoucherCurrentMillesimeStatus) {
      return false;
    }

    const now = Date.now();
    const limitDateOK = now > foodVoucherCurrentMillesimeStatus.rightsReconductionUpdateHintStart
      && now < foodVoucherCurrentMillesimeStatus.rightsReconductionUpdateLimit;

    return limitDateOK && this.allowToModifyRightsReconductionType;
  }

  get allowToModifyRightsReconductionType(): boolean {
    const foodVoucherCurrentMillesimeStatus: AgencyMillesimeStatusModel | null = this.foodVoucherCurrentMillesimeStatus;

    if (!foodVoucherCurrentMillesimeStatus) {
      return false;
    }

    const limitDateOk = this.foodVoucherCurrentMillesimeStatus.rightsReconductionUpdateLimit > Date.now();
    const millesimeWorkflowStateOk = this.foodVoucherCurrentMillesimeStatus.scriptExecutionStatus === 'TODO';

    return limitDateOk && millesimeWorkflowStateOk;
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action async setSelectedAgency(agencyId: string) {
    if (agencyId === this.selectedAgencyId) {
      return;
    }

    this.selectedAgencyId = agencyId;
    await this.refreshCurrentMillesimeStatus();
  }

  @action async refreshCurrentMillesimeStatus(clearExisting: boolean = true) {
    if (clearExisting) {
      this.currentMillesimeStatus = null;
    }

    if (!this.selectedAgencyId) {
      return;
    }

    this.isLoadingCurrentMillesimeStatus = true;

    try {
      this.currentMillesimeStatus = await getAgencyCurrentMillesimeStatus(this.selectedAgencyId);
    } catch (e) {
      //TODO: display error
    }

    this.isLoadingCurrentMillesimeStatus = false;
  }

  @action async setSelectedRightsReconductionType(productType: ProductType, year: number, rightsReconductionType: RightsReconductionType) {
    if (!this.selectedAgencyId) {
      return;
    }

    this.isSavingRightsReconductionType = true;

    try {
      await setSelectedRightsReconductionType(productType, year, this.selectedAgencyId, rightsReconductionType);
    } catch (e) {
      //TODO: display error
    }

    this.isSavingRightsReconductionType = false;

    await this.refreshCurrentMillesimeStatus(false);
  }

  @action async resetSelectedRightsReconductionType(productType: ProductType, year: number) {
    if (!this.selectedAgencyId) {
      return;
    }

    this.isSavingRightsReconductionType = true;

    try {
      await resetSelectedRightsReconductionType(productType, year, this.selectedAgencyId);
    } catch (e) {
      //TODO: display error
    }

    this.isSavingRightsReconductionType = false;

    await this.refreshCurrentMillesimeStatus(false);
  }
}