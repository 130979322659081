import { action, makeAutoObservable, runInAction } from 'mobx';
import { AgencyAdminModel, AgencyAdminSettingModel } from '@assets/models/agencyAdmin/AgencyAdmin.model';
import { getConnectedAgencyAdmin } from '../Services/agencyAdminAPI.service';

export class AgencyAdminStore {
  agencyAdminSelected: AgencyAdminModel | null = null;
  agencyAdminStatus: string = 'PENDING';
  isLoading: boolean = false;
  agencyAdminsList: AgencyAdminSettingModel[] = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action resetAgencyAdmin() {
    this.agencyAdminSelected = null;
    this.agencyAdminsList = [];
    this.agencyAdminStatus = 'PENDING';
  }

  async getConnectedAgencyAdmin(): Promise<void> {
    this.isLoading = true;

    try {

      const connectedAgencyAdmin: AgencyAdminModel = await getConnectedAgencyAdmin();
      runInAction(() => {
        this.agencyAdminSelected = connectedAgencyAdmin;
        this.agencyAdminStatus = 'SUCCESS';
      });
    } catch (e) {
      this.agencyAdminStatus = 'ERROR' + e;
    }
    this.isLoading = false;
  }
}