import {
  BeneficiaryClassificationModel,
  BeneficiaryClassificationProvisionSummary,
} from '../../models/beneficiaries/BeneficiaryClassification.model';
import {
  Classification,
  CreateOneBeneficiaryClassificationRequest,
} from '../../requests/agencies/CreateOneBeneficiaryClassification.request';
import {
  UpdateOneBeneficiaryClassificationRequest,
} from '../../requests/agencies/UpdateOneBeneficiaryClassification.request';
import {
  convertContractPartValuesFromCentsToEurosForDiscreteMethod,
  convertContractPartValuesFromCentsToEurosForLinearMethod,
  convertContractPartValuesFromEurosToCentsForDiscreteMethod,
  convertContractPartValuesFromEurosToCentsForLinearMethod,
} from './agencyContract.util';
import { convertCentToEuro } from '../functions/convertCentToEuro.util';
import { getIncludingTaxesAmount } from '../money/vat.util';

export const DEFAULT_BENEFICIARY_CLASSIFICATION_ID: string = '_DEFAULT_CLASSIFICATION_';

export function getDefaultClassification(classifications: BeneficiaryClassificationModel[]): BeneficiaryClassificationModel | undefined {
  return classifications.find(isDefaultClassification);
}

export function isDefaultClassification(classification: Pick<BeneficiaryClassificationModel, 'uid'>): boolean {
  return classification.uid === DEFAULT_BENEFICIARY_CLASSIFICATION_ID;
}

export function getTotalOfAgencyParticipationAmount(classification: Classification): number {
  switch (classification.creditRedemptionMethod) {
    case 'LINEAR': {
      return classification.contractAgencyPart;
    }
    case 'DISCRETE': {
      const { subAmount, subVatRate, pimAmount, pimVatRate } = classification;
      const totalSub = getIncludingTaxesAmount(subAmount, subVatRate);
      const totalPim = getIncludingTaxesAmount(pimAmount, pimVatRate);

      return totalSub + totalPim;
    }
    default:
      return 0;
  }
}

export function convertBeneficiaryClassificationsFromCentsToEuros(beneficiaryClassifications: BeneficiaryClassificationModel[]): BeneficiaryClassificationModel[] {
  if (!beneficiaryClassifications.length) {
    return [];
  }

  return beneficiaryClassifications
    .map(convertBeneficiaryClassificationFromCentsToEuros)
    .filter((classification: BeneficiaryClassificationModel | null): classification is BeneficiaryClassificationModel => !!classification);
}

export function convertBeneficiaryClassificationFromCentsToEuros(beneficiaryClassification: BeneficiaryClassificationModel): BeneficiaryClassificationModel | null {
  if (beneficiaryClassification.creditRedemptionMethod === 'LINEAR') {
    const { contractFacialValue, contractAgencyPart, contractSubscriptionFee } = beneficiaryClassification;
    const contractPartInCents = convertContractPartValuesFromCentsToEurosForLinearMethod({
      contractFacialValue, contractAgencyPart, contractSubscriptionFee,
    });

    return {
      ...beneficiaryClassification,
      ...contractPartInCents,
    };
  }

  if (beneficiaryClassification.creditRedemptionMethod === 'DISCRETE') {
    const { subAmount, pimAmount, beneficiaryMinimumThreshold, contractSubscriptionFee } = beneficiaryClassification;
    const contractPartInEuros = convertContractPartValuesFromCentsToEurosForDiscreteMethod({
      subAmount, pimAmount, beneficiaryMinimumThreshold, contractSubscriptionFee,
    });

    return {
      ...beneficiaryClassification,
      ...contractPartInEuros,
    };
  }

  return null;
}

export function convertCreateOrUpdateBeneficiaryClassificationRequestFromEurosToCents<T extends CreateOneBeneficiaryClassificationRequest | UpdateOneBeneficiaryClassificationRequest>(request: T): T {
  if (request.creditRedemptionMethod === 'LINEAR') {
    const { contractFacialValue, contractAgencyPart, contractSubscriptionFee } = request;
    const contractPartInCents = convertContractPartValuesFromEurosToCentsForLinearMethod({
      contractFacialValue, contractAgencyPart, contractSubscriptionFee,
    });

    return {
      ...request,
      ...contractPartInCents,
    };
  }

  if (request.creditRedemptionMethod === 'DISCRETE') {
    const { subAmount, pimAmount, beneficiaryMinimumThreshold, contractSubscriptionFee } = request;
    const contractPartInCents = convertContractPartValuesFromEurosToCentsForDiscreteMethod({
      subAmount, pimAmount, beneficiaryMinimumThreshold, contractSubscriptionFee,
    });

    return {
      ...request,
      ...contractPartInCents,
    };
  }

  return request;
}

export function convertBeneficiaryClassificationProvisionSummaryFromCentsToEuros(provisionSummary: BeneficiaryClassificationProvisionSummary): BeneficiaryClassificationProvisionSummary {
  const { provisionAmount, ...rest } = provisionSummary;
  return {
    ...rest,
    provisionAmount: convertCentToEuro(provisionAmount),
  };
}
