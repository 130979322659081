import { action, makeAutoObservable, runInAction } from 'mobx';
import {
  getAgencyBankAccountAlias, getAgencyBeneficiaries,
  getAgencyByAgencyId, getAgencyContractedBusinesses, getAgencyOrganizationInformations,
  getAllAgenciesOfAgencyAdmin, getAuthorizedAgencyManagementUnitsOfConnectedAdmin,
  getBeneficiaryClassificationForAgency,
  updateAgencyRestitution, getAllAgencyAdminsWithSettingsInformation,
} from '../Services/agenciesAPI.service';
import { AgencyModel, RestitutionMethod } from '@assets/models/agencies/Agency.model';
import { AgencyBankingAliasModel, PaymentBankingAliasModel } from '../assets/models/payments/PaymentBankingAlias.model';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import { UpdateAgencyRestitutionRequest } from '@assets/requests/agencies/UpdateAgencyRestitution.request';
import { errorStore } from './Error.store';
import { BeneficiaryClassificationModel } from '@assets/models/beneficiaries/BeneficiaryClassification.model';
import { isDefaultClassification } from '../assets/utils/agencies/beneficiaryClassification.util';
import { subscribeToDocument } from '../Services/firebase.service';
import { Unsubscribe } from 'firebase/firestore';
import { AgencyAdminModel, AgencyAdminSettingModel } from '../assets/models/agencyAdmin/AgencyAdmin.model';
import { ManagementUnitModel } from '../assets/models/agencies/ManagementUnit.model';
import { OrganizationInformationsModel } from '@assets/models/organizationInformations/OrganizationInformations.model';
import { getDefaultAgencyManagementUnit } from '../assets/utils/agencies/managementUnit.util';
import { ContractedBusinessModel } from '../assets/models/discreteVouchers/ContractedBusiness.model';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';

type Status = 'PENDING' | 'LOADING' | 'SUCCESS' | 'ERROR' | 'ERROR_MANGOPAY';

export class AgenciesStore {
  connectedAgencyAdmin: AgencyAdminModel | null = null;

  agenciesList: AgencyModel[] = [];
  isAgenciesLoaded: string = 'pending';

  currentAgency: AgencyModel | null = null;
  isCurrentAgencyLoaded: boolean = false;

  currentAgencyBankAccounts: AgencyBankingAliasModel | null = null;
  isBankAccountsLoading: boolean = false;

  currentAgencyBeneficiaryClassification: BeneficiaryClassificationModel[] | null = null;
  isLoadingBeneficiaryClassification: boolean = false;

  currentAgencyManagementUnits: ManagementUnitModel[] = [];
  isAgencyManagementUnitsLoading: boolean = false;

  currentAgencyAgencyAdmins: AgencyAdminSettingModel[] = [];
  currentAgencyAgencyAdminsStatus: Status = 'PENDING';

  currentAgencyOrganizationInformations: OrganizationInformationsModel[] = [];
  currentAgencyContractedBusinesses: ContractedBusinessModel[] = [];
  currentAgencyBeneficiaries: BeneficiaryModel[] = [];

  isAgencyOrganizationInformationsLoading: boolean = false;

  private currentRestitutionMethod: RestitutionMethod | null = null;
  private currentRestitutionIban = '';
  updateRestitutionStatus: Status = 'PENDING';
  private _isUpdatingRestitution: boolean = false;

  isCreateOrderLocked: boolean = false;
  private unsubscribeToAgencyCreateOrderLockUpdates: Unsubscribe = () => {
  };

  get paymentBankAccountMain(): PaymentBankingAliasModel {
    return this.currentAgencyBankAccounts?.main;
  }

  get paymentBankAccountFees(): PaymentBankingAliasModel | null {
    return this.currentAgencyBankAccounts?.fees;
  }

  get hasFeesCustomerReferenceArea(): boolean {
    return this.isDissociatedPayment && this.hasCustomerReferenceArea;
  }

  get hasCustomerReferenceArea(): boolean {
    return !!this.currentAgency?.customerReferenceArea;
  }

  get hasInternalOrderNumberArea(): boolean {
    return !!this.currentAgency?.internalOrderNumberArea;
  }

  get hasMarketAndCommitmentNumberArea(): boolean {
    return !!this.currentAgency?.marketAndCommitmentNumberArea;
  }

  get isDissociatedPayment(): boolean {
    return this.currentAgency?.paymentMethod === 'DISSOCIATED_BANK_TRANSFER';
  }

  get isPublicMealSubsidyContract(): boolean {
    return this.currentAgency?.contractType === 'PUBLIC_MEAL_SUBSIDY';
  }

  get hasInvoiceInformation(): boolean {
    return this.hasMarketAndCommitmentNumberArea || this.hasInternalOrderNumberArea || this.hasCustomerReferenceArea;
  }

  get restitutionMethod(): RestitutionMethod | null {
    return this.currentRestitutionMethod;
  }

  get restitutionIban(): string {
    return this.currentRestitutionIban;
  }

  get isUpdatingRestitution(): boolean {
    return this._isUpdatingRestitution;
  }

  get currentAgencyDefaultClassification(): BeneficiaryClassificationModel {
    const [defaultClassification] = this.currentAgencyBeneficiaryClassification ?? [];

    return defaultClassification;
  }

  get currentAgencyDefaultContractPart() {
    if (!this.currentAgencyDefaultClassification) {
      return {
        paymentMethod: this.currentAgency?.paymentMethod,
        contractAgencyPart: 0,
        contractFacialValue: 0,
        contractSubscriptionFee: 0,
      };
    }

    if (this.currentAgencyDefaultClassification.creditRedemptionMethod === 'DISCRETE') {
      return {
        paymentMethod: this.currentAgency?.paymentMethod,
        contractAgencyPart: 0,
        contractFacialValue: 0,
        contractSubscriptionFee: 0,
      };
    }

    return {
      paymentMethod: this.currentAgency?.paymentMethod,
      contractAgencyPart: this.currentAgencyDefaultClassification?.contractAgencyPart,
      contractFacialValue: this.currentAgencyDefaultClassification?.contractFacialValue,
      contractSubscriptionFee: this.currentAgencyDefaultClassification?.contractSubscriptionFee,
    };
  }

  get defaultManagementUnitForCurrentAgency(): ManagementUnitModel | null {
    return getDefaultAgencyManagementUnit(this.currentAgencyManagementUnits);
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action subscribeToAgencyCreateOrderLockUpdates(agencyUid: string) {
    this.unsubscribeToAgencyCreateOrderLockUpdates();
    this.unsubscribeToAgencyCreateOrderLockUpdates = subscribeToDocument(
      `AGENCIES/${agencyUid}/LOCKS/CREATE_ORDER`,
      (lockData) => {
        runInAction(() => {
          this.isCreateOrderLocked = !!lockData?.locked;
        });
      },
    );
  }

  @action reset(): void {
    this.isAgenciesLoaded = 'pending';
    this.updateRestitutionStatus = 'PENDING';
    this.isCurrentAgencyLoaded = false;
    this._isUpdatingRestitution = false;
    this.agenciesList = null;
    this.currentAgency = null;
    this.currentAgencyBankAccounts = null;
    this.currentAgencyBeneficiaryClassification = null;
    this.currentAgencyManagementUnits = null;
    this.currentRestitutionIban = '';
    this.currentRestitutionMethod = null;
    this.currentAgencyAgencyAdmins = [];
    this.currentAgencyAgencyAdminsStatus = 'PENDING'
    this.unsubscribeToAgencyCreateOrderLockUpdates();
    localStorage.removeItem('currentAgency');
  }

  @action
  async setConnectedAgencyAdmin(agencyAdmin: AgencyAdminModel): Promise<void> {
    const { companyId, uid: agencyAdminId } = agencyAdmin;
    this.connectedAgencyAdmin = agencyAdmin;
    await this.fetchAllAgenciesOfConnectingAdmin(companyId, agencyAdminId);
  }

  @action setIsUpdatingRestitution(value: boolean) {
    this._isUpdatingRestitution = value;
  }

  @action setCurrentAgency(agency: AgencyModel): void {
    this.currentAgency = agency;
    this.setCurrentRestitutionMethod(agency.restitutionMethod);
    this.setCurrentRestitutionIban(agency.restitutionIban || '');
    this.refreshCurrentAgencyBeneficiaryClassifications();
    this.refreshCurrentAgencyManagementUnits();
    this.refreshCurrentAgencyOrganizationInformations();
    this.refeshCurrentAgencyContractedBusinesses();
    this.refeshCurrentAgencyBeneficiaries();

    localStorage.setItem('currentAgency', JSON.stringify(agency));

    this.subscribeToAgencyCreateOrderLockUpdates(agency.uid);
  }

  @action setPendingCurrentAgency(): void {
    const jsonStringCurrentAgency = localStorage.getItem('currentAgency');
    const pendingCurrentAgency = JSON.parse(jsonStringCurrentAgency);

    if (pendingCurrentAgency) {
      this.setCurrentAgency(pendingCurrentAgency);
    }
  }

  @action setCurrentRestitutionMethod(method: RestitutionMethod) {
    this.currentRestitutionMethod = method;
  }

  @action setCurrentRestitutionIban(iban: string) {
    this.currentRestitutionIban = iban;
  }

  @action
  async refreshCurrentAgencyBeneficiaryClassifications() {
    const { companyId, uid, useBeneficiaryClassification } = this.currentAgency;

    this.isLoadingBeneficiaryClassification = true;
    this.currentAgencyBeneficiaryClassification = null;

    const classifications = await getBeneficiaryClassificationForAgency(companyId, uid);

    this.currentAgencyBeneficiaryClassification = classifications.filter(classification => (
      // Multi-Class: keep all classifications except the default one, unless it has more than one beneficiary attached
      (useBeneficiaryClassification && (!isDefaultClassification(classification) || (isDefaultClassification(classification) && classification.beneficiaryCount > 0)))
      // Mono-Class: only keep the default classification (just in case)
      || (!useBeneficiaryClassification && isDefaultClassification(classification))
    ));

    this.isLoadingBeneficiaryClassification = false;
  }

  @action
  async refreshCurrentAgencyManagementUnits(): Promise<void> {
    const { uid: agencyId } = this.currentAgency;
    const { uid: agencyAdminId } = this.connectedAgencyAdmin;
    this.isAgencyManagementUnitsLoading = true;
    this.currentAgencyManagementUnits = await getAuthorizedAgencyManagementUnitsOfConnectedAdmin(agencyId, agencyAdminId);
    this.isAgencyManagementUnitsLoading = false;
  }

  @action
  async refreshCurrentAgencyOrganizationInformations(): Promise<void> {
    const { uid: agencyId } = this.currentAgency;
    this.isAgencyOrganizationInformationsLoading = true;
    const currentAgencyOrganizationInformations = await getAgencyOrganizationInformations(agencyId);

    // sort alphabetically
    this.currentAgencyOrganizationInformations = currentAgencyOrganizationInformations.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
    this.isAgencyOrganizationInformationsLoading = false;
  }

  @action
  async refeshCurrentAgencyContractedBusinesses(): Promise<void> {
    const { uid: agencyId } = this.currentAgency;
    this.currentAgencyContractedBusinesses = await getAgencyContractedBusinesses(agencyId);
  }

  @action
  async refeshCurrentAgencyBeneficiaries(): Promise<void> {
    const { uid: agencyId } = this.currentAgency;
    this.currentAgencyBeneficiaries = await getAgencyBeneficiaries(agencyId);
  }

  @action
  async refreshAgencyAdminsWithSettings(): Promise<void> {
    const { uid: agencyId, companyId: companyId } = this.currentAgency;
    try {
      this.currentAgencyAgencyAdminsStatus = 'LOADING';
      const admins = await getAllAgencyAdminsWithSettingsInformation(companyId, agencyId);
      if (this.currentAgency?.uid === agencyId) {
        runInAction(() => {
          this.currentAgencyAgencyAdminsStatus = 'SUCCESS';
          this.currentAgencyAgencyAdmins = admins;
        });
      }
    } catch (e) {
      this.currentAgencyAgencyAdminsStatus = 'ERROR';
    }
  }

  async fetchAllAgenciesOfConnectingAdmin(companyId: string, agencyAdminId: string) {
    try {
      const agenciesList: AgencyModel[] = await getAllAgenciesOfAgencyAdmin(companyId, agencyAdminId);
      runInAction(() => {
        if (agenciesList) {
          this.agenciesList = agenciesList;
          this.isAgenciesLoaded = 'success';
          this.isCurrentAgencyLoaded = true;
          if (!this.currentAgency) {
            this.setCurrentAgency(agenciesList[0]);
          }
        }
      });
    } catch (e) {
      this.isAgenciesLoaded = 'error load agencies';
    }
  }

  async getAgencyBankAccountAlias(): Promise<PaymentBankingAliasModel> {
    const { companyId, uid } = this.currentAgency;
    this.isBankAccountsLoading = true;
    const bankAccounts: AgencyBankingAliasModel = await getAgencyBankAccountAlias(companyId, uid);
    runInAction(() => {
      if (bankAccounts) {
        this.currentAgencyBankAccounts = bankAccounts;
      }

      this.isBankAccountsLoading = false;
    });
    return bankAccounts.main;
  }

  async updateRestitutionMethod(request: UpdateAgencyRestitutionRequest): Promise<void> {
    const { companyId, uid: agencyId } = this.currentAgency;
    const { restitutionMethod, iban } = request;
    try {
      this.setIsUpdatingRestitution(true);
      const confirmationResponse: ConfirmationResponse = await updateAgencyRestitution(companyId, agencyId, request);
      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.setCurrentRestitutionMethod(restitutionMethod);
          this.setCurrentRestitutionIban(iban ?? '');
          if (restitutionMethod !== 'RESTITUTION_IBAN_PAYOUT') {
            this.setCurrentRestitutionIban('');
          }
          this.updateRestitutionStatus = 'SUCCESS';
        } else {
          this.updateRestitutionStatus = 'ERROR';
        }
      });
    } catch (e) {
      this.updateRestitutionStatus = 'ERROR';
      if (errorStore.messageToDisplay === 'Error while creating restitution banking account on MANGOPAY side') {
        this.updateRestitutionStatus = 'ERROR_MANGOPAY';
        errorStore.dismiss(e);
      }
    } finally {
      this.setIsUpdatingRestitution(false);
    }
  }
}

export class AgencyDetailsStore {
  isAgencyLoaded: boolean = false;
  agencyDetails: AgencyModel = null;
  agencyError: string;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action reset() {
    this.isAgencyLoaded = false;
    this.agencyDetails = null;
    this.agencyError = null;
  }

  async getOneAgency(agencyId: string): Promise<AgencyModel> {
    try {
      this.isAgencyLoaded = true;
      const agencyDetailsRes = await getAgencyByAgencyId(agencyId);
      runInAction(() => {
        this.agencyDetails = agencyDetailsRes;
        this.isAgencyLoaded = false;
      });
      return agencyDetailsRes;
    } catch (e) {
      this.isAgencyLoaded = false;
      this.agencyError = 'error agency loaded';
    }
  }
}
