import { useMemo } from 'react';
import { BeneficiaryClassificationModel } from '../assets/models/beneficiaries/BeneficiaryClassification.model';
import { getTotalOfAgencyParticipationAmount } from '../assets/utils/agencies/beneficiaryClassification.util';

export interface ContractPart {
  contractAgencyPart: number;
  contractFacialValue: number;
}

function getClassificationParticipation(classification: BeneficiaryClassificationModel) {
  const classificationAgencyPart = getTotalOfAgencyParticipationAmount(classification);
  let classificationFacialValue: number = 0;

  switch (classification.creditRedemptionMethod) {
    case 'LINEAR': {
      const { contractFacialValue = 0 } = classification;
      classificationFacialValue = contractFacialValue;
      break;
    }
    case 'DISCRETE': {
      const { beneficiaryMinimumThreshold = 0 } = classification;
      classificationFacialValue = beneficiaryMinimumThreshold;  // minimum to be paid by the beneficiary (before any agency participation)
      break;
    }
    default:
      break;
  }

  return { classificationAgencyPart, classificationFacialValue };
}

function useClassificationsParticipation(agencyClassifications: BeneficiaryClassificationModel[] | null): ContractPart {
  return useMemo(() => {
    if (!agencyClassifications || agencyClassifications.length === 0) {
      return { contractAgencyPart: 0, contractFacialValue: 0 };
    }

    const totalContractPart: ContractPart = agencyClassifications.reduce(
      ({
         contractAgencyPart, contractFacialValue,
       }: ContractPart, classification: BeneficiaryClassificationModel) => {
        const {
          classificationAgencyPart, classificationFacialValue,
        } = getClassificationParticipation(classification);
        return {
          contractAgencyPart: contractAgencyPart + classificationAgencyPart,
          contractFacialValue: contractFacialValue + classificationFacialValue,
        };
      }, { contractAgencyPart: 0, contractFacialValue: 0 });

    const classificationsLength: number = agencyClassifications.length;
    return {
      contractAgencyPart: totalContractPart.contractAgencyPart / classificationsLength,
      contractFacialValue: totalContractPart.contractFacialValue / classificationsLength,
    };
  }, [agencyClassifications]);
}

export default useClassificationsParticipation;
